import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 8-8-8\\@75% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`30 Power Cleans for time. (135/95)`}</p>
    <p>{`rest, 2:00 then`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`25-KBS’s (53/35)`}</p>
    <p>{`25-V Ups`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts this Thursday, February 23rd! Sign up now
at: Games.crossfit.com.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts March 4th. Email Eric at
fallscitystrength\\@gmail for more info.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      